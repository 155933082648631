import {
  DeviceTrackedEventCellInfo,
  EventsTableItem,
  GreenlightTestData,
  ISectionEvent
} from "src/interfaces";
import {ONGUARD_COMMAND_KEYPAD_IDS, SECTIONS} from "src/constants";
import {determineDeviceTrackedEventCellInfo, isEventApplicable} from "src/utils/trackedEventUtils";

export const exportDevicesCSV = (siteName: string, deviceList: EventsTableItem[], sectionName: string | null = null) => {
  // Set header for file name
  const sectionHeader = sectionName ?? 'AllDevices';

  if (deviceList.length < 1)
    throw new Error("Unable to export, no devices provided");

  // Filter only for devices with all tests successful
  const successfulDevices = deviceList.filter(device => device.tests_successful);

  let csvContent = `parentID,childID,subchildID,deviceName,deviceID\n`;

  // Add to CSV content
  successfulDevices.forEach(device => {
    csvContent += `${device.device_id.split('_')},${device.device_name},${device.device_id}\n`;
  });

  const csvBlob = new Blob([csvContent], {type: 'text/csv'});
  const url = window.URL.createObjectURL(csvBlob);
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', `GreenlightExport-${siteName}-${sectionHeader}.csv`);
  a.click();
}

export const exportFailedDevicesCSV = (siteName: string, deviceList: EventsTableItem[], trackedEvents: ISectionEvent, sectionHeader: string | null = null) => {
  if (deviceList.length < 1) {
    throw new Error("Unable to export, no devices provided");
  }

  const exportDevices = deviceList.filter(device => !device.tests_successful);
  const exportEvents: string[] = [];
  if (sectionHeader) {
    const section = SECTIONS.find(section => section.header === sectionHeader);
    if (!section) {
      throw new Error("Unable to export, section not found");
    }
    trackedEvents[section.id].forEach(trackedEvent => {
      exportEvents.push(trackedEvent);
    });
  } else {
    SECTIONS.forEach(section => {
      trackedEvents[section.id].forEach(trackedEvent => {
        exportEvents.push(trackedEvent);
      });
    });
  }

  let csvContent = `parentID,childID,subchildID,deviceName,deviceID,notes,section,${exportEvents.join(',')}\n`;

  // Add to CSV content
  exportDevices.forEach(device => {
    let section = 'Unknown';
    if (device.device_type.includes('input') || device.device_type.includes('output')) {
      section = 'Inputs and Outputs';
    } else if (device.device_type_id && ONGUARD_COMMAND_KEYPAD_IDS.includes(device.device_type_id)) {
      section = 'Command Keypad';
    } else {
      section = 'Readers';
    }

    if (device.device_notes && device.device_notes.length > 0) {
      csvContent += `${device.device_id.split('_')},${device.device_name},${device.device_id},"${device.device_notes.join('; ')}",${section}`;
    } else {
      csvContent += `${device.device_id.split('_')},${device.device_name},${device.device_id},Unsuccessful,${section}`;
    }

    exportEvents.forEach(exportEvent => {
      // @ts-ignore
      const deviceEvent: GreenlightTestData | null | undefined = device[`EVENT_${exportEvent.replace(/\s/g, '_')}`];
      if (deviceEvent === undefined || deviceEvent === null) {
        csvContent += ',N/A';
      } else {
        const deviceEventTestData = deviceEvent as GreenlightTestData;
        if (!isEventApplicable(exportEvent, device.device_name, device.device_type, device.reader_config)) {
          csvContent += ',N/A';
        } else if (deviceEventTestData.isOverridden) {
          csvContent += `,N/A (Overridden)`
        } else {
          const result: DeviceTrackedEventCellInfo = determineDeviceTrackedEventCellInfo(exportEvent, deviceEventTestData);
          csvContent += `,"${result.eventText}"`;
        }
      }
    });
    csvContent += `\n`;
  });

  const header = sectionHeader ?? 'AllDevices';
  const csvBlob = new Blob([csvContent], {type: 'text/csv'});
  const url = window.URL.createObjectURL(csvBlob);
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', `GreenlightExport-${siteName}-${header}-Failed.csv`);
  a.click();
}