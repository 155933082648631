/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const publishDeviceEvent = /* GraphQL */ `mutation PublishDeviceEvent(
  $deviceID: String!
  $eventName: String!
  $siteName: String!
  $data: AWSJSON!
) {
  publishDeviceEvent(
    deviceID: $deviceID
    eventName: $eventName
    siteName: $siteName
    data: $data
  ) {
    deviceID
    eventName
    eventReceivedSuccess
    greenlightProcessTimestampUTC
    greenlightPublishTimestampUTC
    isCancelled
    lastSuccessID
    lastSuccessfulEventTimestampUTC
    lastTestID
    normalizerEventEmployeeLogin
    siteName
    testDurationSeconds
    testEndTimestampUTC
    testStartTimestampUTC
    testSubmittedBy
  }
}
` as GeneratedMutation<
  APITypes.PublishDeviceEventMutationVariables,
  APITypes.PublishDeviceEventMutation
>;
export const publishEventTest = /* GraphQL */ `mutation PublishEventTest($input: GreenlightActionInput!) {
  publishEventTest(input: $input) {
    siteName
    submitted {
      eventName
      deviceID
    }
  }
}
` as GeneratedMutation<
  APITypes.PublishEventTestMutationVariables,
  APITypes.PublishEventTestMutation
>;
export const cancelEventTest = /* GraphQL */ `mutation CancelEventTest($input: GreenlightActionInput!) {
  cancelEventTest(input: $input) {
    siteName
    cancelled {
      eventName
      deviceID
    }
  }
}
` as GeneratedMutation<
  APITypes.CancelEventTestMutationVariables,
  APITypes.CancelEventTestMutation
>;
export const updateEventOverride = /* GraphQL */ `mutation UpdateEventOverride($input: GreenlightActionInput!) {
  updateEventOverride(input: $input) {
    siteName
    markedNA {
      eventName
      deviceID
    }
    unmarkedNA {
      eventName
      deviceID
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventOverrideMutationVariables,
  APITypes.UpdateEventOverrideMutation
>;
export const updateDeviceNotes = /* GraphQL */ `mutation UpdateDeviceNotes($input: GreenlightActionInput!) {
  updateDeviceNotes(input: $input) {
    siteName
    submitted {
      eventName
      deviceID
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceNotesMutationVariables,
  APITypes.UpdateDeviceNotesMutation
>;
