import * as APIt from 'src/API';
import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {
  Box,
  Button,
  FormField, Input,
  Modal,
  Multiselect,
  MultiselectProps, SelectProps,
  SpaceBetween
} from "@amzn/awsui-components-react";
import {EventsTableItem} from "src/interfaces";
import {NotificationActionType, useNotifications} from 'src/hooks/notifications';
import React, {useState} from 'react';
import {appBaseState, appEventsBaseState} from 'src/stores/app';
import {useHookstate} from '@hookstate/core';
import {updateDeviceNotes} from "src/graphql/mutations";
import {useParams} from 'react-router-dom';
import {DEVICE_NOTES} from "src/constants";

interface DeviceNotesModalProps {
  item: EventsTableItem;
  sectionId: string;
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const getDeviceNoteOptions = (): SelectProps.Options => {
  return DEVICE_NOTES.map((n: string) => {
    return {
      label: n,
      value: n
    }
  });
}

const getInitialDeviceNotes = (item: EventsTableItem): MultiselectProps.Options => {
  return item.device_notes ? item.device_notes.map(note => {
    return {
      label: note,
      value: note
    }
  }) : [];
}

export function DeviceNotesModal({item, sectionId, isVisible, setVisible}: DeviceNotesModalProps) {
  const appState = useHookstate(appBaseState);
  const {dispatch} = useNotifications();

  const [actionSubmitting, setActionSubmitting] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState(getInitialDeviceNotes(item));

  let {siteName} = useParams();

  const deviceNotesOnChange = (detail: MultiselectProps.MultiselectChangeDetail): void => {
    setSelectedNotes(detail.selectedOptions);
  }

  const isSelectionDifferent = (newNotes: string[]): boolean => {
    if (!item.device_notes) {
      return (newNotes.length > 0);
    } else if (newNotes.length !== item.device_notes.length) {
      return true;
    } else {
      for (const note of newNotes) {
        if (!item.device_notes.includes(note)) {
          return true;
        }
      }
      return false;
    }
  }

  const handleSubmit = async () => {
    if (!siteName || !appState.user.value.username) {
      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: {
          type: 'error',
          header: 'Failed to submit device notes',
          content: 'There was an error when trying to submit the request. Try refreshing the page and resubmitting your request.'
        }
      });

      setVisible(false);
      return;
    }

    setActionSubmitting(true);

    const newNotes: string[] = [];
    selectedNotes.forEach(option => {
      if (option.value) newNotes.push(option.value);
    });

    if (isSelectionDifferent(newNotes)) {
      try {
        const submitReq = await API.graphql(
          graphqlOperation(updateDeviceNotes, {
            input: {
              siteName: siteName.toUpperCase(),
              data: JSON.stringify({
                submitter: appState.user.value.username,
                devices: [{
                  deviceID: item.device_id,
                  deviceNotes: newNotes
                }]
              })
            }
          })
        ) as GraphQLResult<APIt.UpdateDeviceNotesMutation>;

        if (submitReq.errors) {
          console.error(submitReq.errors);
          dispatch({
            type: NotificationActionType.ADD_NOTIFICATION,
            message: {
              type: 'error',
              header: 'Unexpected response when submitting request',
              content: 'There was an unexpected response from the API when submitting device notes. Try resubmitting your request or refreshing the page.'
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          message: {
            type: 'error',
            header: 'Failed to submit request',
            content: 'There was an issue submitting the new device notes. Try waiting a bit and resubmit your request or refresh the page.'
          }
        });
      }
    }

    setActionSubmitting(false);
    setVisible(false);
  }

  return (
    <Modal
      onDismiss={() => {
        if (actionSubmitting) {
          return;
        } else {
          setVisible(false);
        }
      }}
      visible={isVisible}
      header='Device Notes'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' loading={actionSubmitting} onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={() => handleSubmit()} loading={actionSubmitting}>
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction='vertical' size='l'>
        <FormField
          label='Device'
          description='Device to update'
        >
          <Input
            value={item.device_name}
            readOnly
          />
        </FormField>
        <FormField
          label="Notes"
        >
          <Multiselect
            expandToViewport
            onChange={({detail}) => {
              deviceNotesOnChange(detail)
            }}
            options={getDeviceNoteOptions()}
            selectedOptions={selectedNotes}
            placeholder='Select notes'
            tokenLimit={3}
            filteringType='auto'
            i18nStrings={{
              tokenLimitShowMore: "Show more selected events",
              tokenLimitShowFewer: "Show fewer selected events"
            }}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  )
}