import { SelectProps } from "@amzn/awsui-components-react";
import {ISection} from "src/interfaces";

export const APP_BASE_TITLE = "Greenlight (Site Testing Tool)";
export const DEFAULT_USER_LOCALE = navigator.language || 'en-US';

export const DEVICE_NOTES: string[] = [
    "Card Reader Not Responding",
    "DC Line Error",
    "DC Not Installed",
    "DC Tested But Hardware Not Installed (SLP)",
    "Device Activates Improper Input",
    "Device Damaged",
    "Door Hardware Not Installed",
    "Grounded Loop",
    "Improper/No Linkage Programmed",
    "Input Constantly Active",
    "Input Not Activating",
    "Invalid Card Format",
    "Line Error",
    "Open Line",
    "Output Activates - Not Audible (Check Power)",
    "REX Line Error",
    "REX Not Installed",
    "SAFE/LED Activates Properly - Greenlight Not Responding",
    "Shorted Line",
    "Strike Relay Activates - Hardware Not Unlocking"
];

export const GREENLIGHT_DEVICE_NOTES: string = "GREENLIGHT_DEVICE_NOTES";

export const NAV_LINKS = {
    GREENLIGHT_PUBLIC_WIKI: "https://w.amazon.com/bin/view/SIDE/Greenlight/",
    GUARDIAN_HUB_URL: "https://guardian.gso.amazon.dev/",
    RIVER_WORKFLOW: "https://river.amazon.com/?org=AccSys&q0=dd4fc76a-e1be-40c1-978c-5db20c1de6a3"
}

export const ONGUARD_COMMAND_KEYPAD_IDS = [144, 151, 152, 153];

interface ILanguage {
    id: string;
    text: string;
}

export const LANGUAGE_PREFERENCES: SelectProps.Options = [
    { value: "en-US", label: "English" },
    { value: "fr-FR", label: "French" },
    { value: "de-DE", label: "German" },
    { value: "es-ES", label: "Spanish" },
    { value: "pt-PT", label: "Portuguese" },
    { value: "it-IT", label: "Italian" },
    { value: "hi-IN", label: "Hindi" },
    { value: "ja-JP", label: "Japanese" },
    { value: "ar-AE", label: "Arabic (U.A.E.)" },
    { value: "ar-SA", label: "Arabic (Saudi Arabia)" },
    { value: "cs-CZ", label: "Czech (Czech Republic)" },
    { value: "en-AU", label: "English (Australia)" },
    { value: "en-CA", label: "English (Canada)" },
    { value: "en-GB", label: "English (United Kingdom)" },
    { value: "en-SG", label: "English (Singapore)" },
    { value: "es-MX", label: "Spanish (Mexico)" },
    { value: "fr-CA", label: "French (Canada)" },
    { value: "nl-NL", label: "Dutch (Netherlands)" },
    { value: "pl-PL", label: "Polish (Poland)" },
    { value: "sk-SK", label: "Slovak (Slovakia)" },
    { value: "tr-TR", label: "Turkish (Turkey)" },
    { value: "zh-CN", label: "Chinese (S)" },
    { value: "zh-TW", label: "Chinese (T)" }
];

export const SECTIONS: ISection[] = [
    { id: "readers", header: "Readers", device_types: ['card_reader'] },
    { id: "inputs_outputs", header: "Inputs and Outputs", device_types: ['input', 'output'] },
    { id: "command_keypads", header: "Command Keypad", device_types: ['card_reader'] } // uses card_reader devices but later filtered based on type IDs
]