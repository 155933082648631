/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onDeviceEventPublished = /* GraphQL */ `subscription OnDeviceEventPublished($siteName: String!) {
  onDeviceEventPublished(siteName: $siteName) {
    deviceID
    eventName
    eventReceivedSuccess
    greenlightProcessTimestampUTC
    greenlightPublishTimestampUTC
    isCancelled
    lastSuccessID
    lastSuccessfulEventTimestampUTC
    lastTestID
    normalizerEventEmployeeLogin
    siteName
    testDurationSeconds
    testEndTimestampUTC
    testStartTimestampUTC
    testSubmittedBy
  }
}
` as GeneratedSubscription<
  APITypes.OnDeviceEventPublishedSubscriptionVariables,
  APITypes.OnDeviceEventPublishedSubscription
>;
export const onDeviceNotesUpdated = /* GraphQL */ `subscription OnDeviceNotesUpdated($siteName: String!) {
  onDeviceNotesUpdated(siteName: $siteName) {
    siteName
    submitted {
      eventName
      deviceID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnDeviceNotesUpdatedSubscriptionVariables,
  APITypes.OnDeviceNotesUpdatedSubscription
>;
export const onEventTestStarted = /* GraphQL */ `subscription OnEventTestStarted($siteName: String) {
  onEventTestStarted(siteName: $siteName) {
    siteName
    submitted {
      eventName
      deviceID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnEventTestStartedSubscriptionVariables,
  APITypes.OnEventTestStartedSubscription
>;
export const onEventTestCancelled = /* GraphQL */ `subscription OnEventTestCancelled($siteName: String!) {
  onEventTestCancelled(siteName: $siteName) {
    siteName
    cancelled {
      eventName
      deviceID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnEventTestCancelledSubscriptionVariables,
  APITypes.OnEventTestCancelledSubscription
>;
export const onEventOverrideUpdated = /* GraphQL */ `subscription OnEventOverrideUpdated($siteName: String!) {
  onEventOverrideUpdated(siteName: $siteName) {
    siteName
    markedNA {
      eventName
      deviceID
    }
    unmarkedNA {
      eventName
      deviceID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnEventOverrideUpdatedSubscriptionVariables,
  APITypes.OnEventOverrideUpdatedSubscription
>;
